<template>
    <div class="dashboard-container">
      <div class="dashboard-content">
        <div class="type-title">
            <div>
                <b>我的首页</b>
                <span class="el-icon-view"></span>
            </div>
        </div>
          <!--<div class="condition-container">-->
              <!--<dl>-->
                  <!--<dt>-->
                      <!--<img src="./icon01.png"/>-->
                  <!--</dt>-->
                  <!--<dd>-->
                      <!--<p class="name">支付金额(元)</p>-->
                      <!--<p class="number">{{map.sum}}</p>-->
                      <!--<p class="old">昨日:{{yesterdayMap.sum}}</p>-->
                  <!--</dd>-->
              <!--</dl>-->
              <!--<dl>-->
                  <!--<dd>-->
                      <!--<p class="name">支付订单数</p>-->
                      <!--<p class="number">{{map.count}}</p>-->
                      <!--<p class="old">昨日:{{yesterdayMap.count}}</p>-->
                  <!--</dd>-->
              <!--</dl>-->
              <!--<dl>-->
                  <!--<dd>-->
                      <!--<p class="name">支付人数</p>-->
                      <!--<p class="number">{{map.countMember}}</p>-->
                      <!--<p class="old">昨日:{{yesterdayMap.countMember}}</p>-->
                  <!--</dd>-->
              <!--</dl>-->
              <!--&lt;!&ndash;<dl>-->
                  <!--<dd>-->
                      <!--<p class="name">访客数</p>-->
                      <!--<p class="number">10</p>-->
                      <!--<p class="old">昨日:0</p>-->
                  <!--</dd>-->
              <!--</dl>&ndash;&gt;-->
              <!--<dl>-->
                  <!--<dt>-->
                      <!--<img src="./icon02.png"/>-->
                  <!--</dt>-->
                  <!--<dd>-->
                      <!--<p class="name">待发货订单</p>-->
                      <!--<p class="number" style="color:#ff2b5f" @click="deliver">{{map.countNoSend}}</p>-->
                  <!--</dd>-->
              <!--</dl>-->
          <!--</div>-->
         <!-- <div class="condition-container" style="border-top:1px #f1f1f1 solid">

              <dl>
                  <dd>
                      <p class="name">维权订单</p>
                      <p class="number" style="color:#ff2b5f">0</p>
                  </dd>
              </dl>
              <dl>
                  <dd>
                      <p class="name">未回复客户数</p>
                      <p class="number" style="color:#ff2b5f">0</p>
                  </dd>
              </dl>
              <dl>
              </dl>
          </div>-->
      </div>
        <!--<div class="dashboard-content">-->
            <!--<div class="type-title">-->
                <!--<div>-->
                    <!--<b>常用功能</b>-->
                <!--</div>-->
            <!--</div>-->
            <!--<div style="display: flex">-->
                <!--<ul class="function" style="width:70%">-->
                    <!--&lt;!&ndash;<li><span>微信公众号</span></li>&ndash;&gt;-->
                   <!--&lt;!&ndash; <li style="background-position: left -155px" @click="aaa"><span>发布商品</span></li>&ndash;&gt;-->
                    <!--&lt;!&ndash;<li style="background-position: left -296px"><span>编辑微页面</span></li>&ndash;&gt;-->
                    <!--&lt;!&ndash;<li style="background-position: left -202px"><span>分销员</span></li>&ndash;&gt;-->
                    <!--&lt;!&ndash;<li style="background-position: left -54px"><span>收入提现</span></li>&ndash;&gt;-->
                    <!--&lt;!&ndash;<li style="background-position: left -100px"><span>优惠券</span></li>&ndash;&gt;-->
                    <!--&lt;!&ndash;<li style="background-position: left -54px"><span>多人拼团</span></li>&ndash;&gt;-->
                    <!--&lt;!&ndash;<li style="background-position: left -54px"><span>微信小程序</span></li>&ndash;&gt;-->
                    <!--&lt;!&ndash;<li style="background-position: left -54px"><span>发券宝</span></li>&ndash;&gt;-->
                <!--</ul>-->
                <!--<div>-->
                    <!--<img src="./gt2.png" style="width: 150px" height="150">-->
                <!--</div>-->
            <!--</div>-->

        <!--</div>-->
    </div>
</template>

<script>

    export default {
        name: 'dashboard',
        data() {
            return {
                map:'',
                yesterdayMap:'',
            }
        },
        components: {
        },
        async created () {

        },
        methods:{
            aaa(){
                this.$router.push(`/skus/sku/insert`)
            },
            deliver(){
                this.$router.push(`/delivers/deliver/?orderDeliver=`+'0')
            },

            //发布商品

            //查询昨天的信息
            async getYesterdayPaymoney(){
                let res = await this.$get("/admin/getYesterdayPaymoney",{

                });
                this.yesterdayMap = res
            },

            //查询全部的金额 ，订单数 ，支付人数
            async getPaymoney(){
                let res = await this.$get("/admin/getPaymoney",{

                });
                this.map = res
                //查询全部订单个数
            }

        }
    }


</script>


<style scoped>
    .dashboard-container{
        padding:10px;
        height: 100%;
        background: #f2f2f2;
        box-sizing: border-box;
    }
    .dashboard-content{
        padding: 0 15px;
        margin-bottom:10px;
        background: #fff;
    }
    .function{
        padding:0px 30px 20px;
        overflow: hidden;
    }
    .function li{
        padding-left: 26px;
        float:left;
        width:33.3%;
        font-size: 12px;
        color:#474f52;
        box-sizing: border-box;
        line-height: 40px;
        background: url(icon.png) no-repeat left -8px;
    }
    .function li span{
        cursor: pointer;
    }
    .condition-container{
        padding:10px 20px 20px;
        display: flex;
    }

    .condition-container dl{
        flex: 1;
        display: flex;
    }
    .condition-container dt{
        padding-right: 20px;
    }
    .condition-container dl .name{
        font-size: 13px;
        color:#444e57;
    }
    .condition-container dl .number{
        font-size: 18px;
        line-height: 36px;
        color:#1b80ff;
    }
    .condition-container dl .old{
        font-size: 13px;
        color:#706d6b;
    }

    .type-title{
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        padding-left: 20px;
    }

    .type-title b{
        font-size: 14px;
        color:#212540
    }
    .more{
        padding-right:10px;
        font-size: 12px;
        color:#aca59f
    }
    .more-link{
        margin-right:20px;
        font-size: 12px;
        color:#559cff
    }
</style>
